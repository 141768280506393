import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import "../main.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import styled from 'styled-components';
import { getFinancingOptions, createFinancingOptions, getDocumentEstimateData, elasticSearchFetchDoc, elasticSearchUpdateDocOrTemp, getContactById, generatePdf, sendEmail } from '../graphql/queries';
import Amplify, { API } from 'aws-amplify';
import config from "../aws-exports";
import { isEmpty, parseNumber, formatPhoneNumber, calculateData, formatter, calculateAdjQuantity, calculateNetCost , getSantizedSublineItem} from '../Components/CommonComponents/helper';
import moment from "moment";
import * as queryString from "query-string";
import { navigate } from "gatsby";
import Tooltip from "rc-tooltip";
import Loader from '../assets/loading.gif';
import Loader2 from '../assets/pageload.gif';
import print from '../assets/print-icon.svg';
import email from '../assets/email.svg';
import file from '../assets/file.svg';
import edit from '../assets/edit.svg';
import calculator from '../assets/calculator.svg';
import arrowDown from '../assets/arrow-down.svg';
import { HiScissors } from "react-icons/hi";
import CameraLogo from '../assets/dollar-bill.png'
import ToastBox from "../Components/Toast/Toast";
import SendEmailModal from '../Components/Modals/SendEmailModal';
import { browserName } from "react-device-detect";
import ReactToPrint from 'react-to-print';
import { FaCircleNotch } from "react-icons/fa";
import NotesIcon from "../assets/Notes_Icon.svg";
import secureLocalStorage from "react-secure-storage";
import EstimateHeader from "../Components/EstimatePreview/EstimateHeader";
import EstimateTable from "../Components/EstimatePreview/EstimateTable";
import EstimateFooter from "../Components/EstimatePreview/EstimateFooter";
import PageFooter from "../Components/EstimatePreview/PageFooter";
import {apiCallWithSubscription} from '../Components/CommonComponents/helper.js';
Amplify.configure(config);

export default function Pdftable(props) {
  const { consoleLog } = require('../Components/commonFunctions.js');
  const dispatch = useDispatch();
  const url_params = queryString.parse(props.location.search);
  const invoice = parseInt(url_params.invoice) || 0;
  const [defContactFirstName, setdefContactFirstName] = useState("");
  const [defContactLastName, setdefContactLastName] = useState("");
  const [defContactEmail, setdefContactEmail] = useState("");
  const [defContactPhoneNo, setdefContactPhoneNo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [financingOptions, setFinancingOptions] = React.useState([]);
  useEffect(() => {
    setdefContactFirstName(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_estimate_contact_first_name)
    setdefContactLastName(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_estimate_contact_last_name)
    setdefContactEmail(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_estimate_contact_email)
    setdefContactPhoneNo(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_estimate_contact_phone)
    setCompanyName(JSON.parse(secureLocalStorage.getItem("client_data")).company)
  }, []);

  async function fetchFinancingOptions(estData) {
    if (typeof estData.financing_options != 'undefined' && estData.financing_options != '' && estData.financing_options != null) {
      consoleLog("fetchFinancingOptions>", JSON.parse(estData.financing_options));
      setFinancingOptions(JSON.parse(estData.financing_options));
      return JSON.parse(estData.financing_options);
    } else {
      if (typeof url_params.doc_id != 'undefined') {
        consoleLog("fetchFinancingOptions doc_id>", url_params.doc_id);
        const get_financing_options = await API.graphql({
          query: getFinancingOptions,
          variables: {
            doc_id: url_params.doc_id
          },
        });
        consoleLog("fetchFinancingOptions get_financing_options>", get_financing_options);
        let data = JSON.parse(get_financing_options?.data?.getFinancingOptions);
        consoleLog("fetchFinancingOptions data>", data);
        if (data.length > 0) {
          setFinancingOptions(data);
          return data;
        } else {
          let userId = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
          let f_options = [{ "user_id": userId, "doc_id": url_params.doc_id, "percentage": 0.00, "months": 0, "is_active": "Y", "is_default": "N" }, { "user_id": userId, "doc_id": url_params.doc_id, "percentage": 0.00, "months": 0, "is_active": "Y", "is_default": "N" }, { "user_id": userId, "doc_id": url_params.doc_id, "percentage": 0.00, "months": 0, "is_active": "Y", "is_default": "N" }];
          setFinancingOptions(f_options);
          return f_options;
        }
      }
    }
  }

  async function getDocument() {
    let data = {};
    let estimateData = {};
    const docId = url_params.doc_id ? url_params.doc_id : null;

    if (docId) {

      const templateRecordElastic = await API.graphql({
        query: elasticSearchFetchDoc,
        variables: {
          doc_id: docId
        },
        fetchPolicy: "no-cache" // Fetch data directly from the server, bypassing cache
      });

      estimateData = JSON.parse(JSON.parse(templateRecordElastic.data.elasticSearchFetchDoc)).data;
      consoleLog("data from elastic...", estimateData);
      if (Object.keys(estimateData).length == 0) {
        estimateData = await apiCallWithSubscription({doc_id: docId} , "getDocumentEstimateData");
        consoleLog("data from db...", estimateData);
      }

      estimateData.doc_id = docId;

      let f_options = await fetchFinancingOptions(estimateData);
      estimateData.financing_options = f_options;

      consoleLog('fetchFinancingOptions>>>', estimateData);

      consoleLog('financingOptions is_active', estimateData.financing_options[0].is_active + ", " + estimateData.financing_options[1].is_active + ", " + estimateData.financing_options[2].is_active);

      setStData(estimateData);

    } else {
      estimateData = stData;
    }
    data = calculateData(estimateData);

    if (data.totalQuantity <= 0) {
      return navigate(`/template?template_id=${estimateData.template_id}&doc_type_id=${estimateData.doc_type_id}&doc_id=${estimateData.doc_id}`, { state: { error: true } });
    }

    let contactInfo = {};
    if (stData.contact_info) {
      contactInfo = JSON.parse(stData.contact_info);
      contactInfo.zone_name = contactInfo && contactInfo.name ? contactInfo.name : '';
      setContact(contactInfo);
      consoleLog("contact info from elastic search.....", contactInfo);
    } else {
      const getContact = await API.graphql({
        query: getContactById,
        variables: {
          contact_id: estimateData.contact_id
        }
      });
      consoleLog('contact info from db.....', getContact);
      contactInfo = JSON.parse(getContact.data.getContactById);
      setContact(contactInfo && contactInfo[0]);
      consoleLog("contact info from db.....", contactInfo);
    }

    let ownerInfo = {};
    if (stData.property_owner_info) {
      ownerInfo = JSON.parse(stData.property_owner_info);
      setPropertyOwner(ownerInfo);
      consoleLog("property owner info from elastic search.....", ownerInfo);
    } else {
      if (!estimateData.owner_contact_id) {
        estimateData.owner_contact_id = stData.owner_contact_id;
      }
      const getPropertyOwner = await API.graphql({
        query: getContactById,
        variables: {
          contact_id: estimateData.owner_contact_id
        }
      });
      consoleLog('property owner info from db.....', getPropertyOwner);
      ownerInfo = JSON.parse(getPropertyOwner.data.getContactById);
      consoleLog('property owner info from db.....', ownerInfo);
      if (ownerInfo.length) {
        ownerInfo[0].name = ownerInfo && ownerInfo[0] ? `${ownerInfo[0].first_name} ${ownerInfo[0].last_name}` : '';
        consoleLog('property owner info from db.....', ownerInfo);
        setPropertyOwner(ownerInfo && ownerInfo[0]);
      }
    }

    setTaxes(data.taxes);
    setMarkups(data.markups)
    setSubTotal(data.subTotal);
    console.log("total_data", data.total);
    setTotal(data.total);

    console.log('xxx>>>', data);
    console.log("total", total);

    setLoading(false);
    pdfDownloadx(url_params.invoice, contactInfo, ownerInfo).then(
      function (response) {
        consoleLog('pdfDownloadx response>>>', response);
        if (response) {
          const getUrl = JSON.parse(response?.data?.generatePdf);
          let pdf = getUrl.message.Location;
          let stDataCopy = stData;
          if (url_params.invoice == 1) {
            stDataCopy.invoice_pdf = pdf;
          } else {
            stDataCopy.pdf = pdf;
          }
          //update the document on elasticsearch
          //Comment this code and updaing elasticSearch directly from LAMBDA Function
          /*let temp = JSON.stringify(stDataCopy);
          API.graphql({
            query: elasticSearchUpdateDocOrTemp,
            variables: {
              id: stData.doc_id,
              index: 'prodocs-documents',
              template: temp
            },
          });
          setStData(stDataCopy);
          dispatch({ type: "TEMPLATE_STRUCTURE", value: stDataCopy });*/
          setDownload(false);
        }
      }
    );
  }
  async function pdfDownloadx(invoice, contactInfo = [], ownerInfo = []) {
    let pdf;
    let data = {};

    if (invoice == 1) {
      pdf = stData.invoice_pdf;
    } else {
      pdf = stData.pdf;
    }
    consoleLog('pdfDownloadx invoice>>>', invoice);
    consoleLog('pdfDownloadx stData>>>', stData);
    consoleLog('pdfDownloadx pdf>>>', pdf);

    consoleLog('pdfDownloadx contactInfo>>>', contactInfo);
    consoleLog('pdfDownloadx contact>>>', contact);
    let c_info = {};
    if (typeof contact == 'object' && Object.keys(contact).length != 0) {
      consoleLog('pdfDownloadx contact if>>>', contact);
      c_info = contact;
    } else {
      consoleLog('pdfDownloadx contact else>>>', typeof contactInfo);
      if (Array.isArray(contactInfo)) {
        c_info = contactInfo[0];
        consoleLog('pdfDownloadx contact if>>>', contactInfo[0]);
      } else {
        c_info = contactInfo;
        consoleLog('pdfDownloadx contact else>>>', contactInfo);
      }
    }
    consoleLog('pdfDownloadx c_info>>>', c_info);
    consoleLog('pdfDownloadx ownerInfo>>>', ownerInfo);
    consoleLog('pdfDownloadx ownerInfo>>>', Object.keys(propertyOwner).length);
    consoleLog('pdfDownloadx propertyOwner>>>', propertyOwner);
    let o_info = {};
    if (typeof propertyOwner == 'object' && Object.keys(propertyOwner).length !== 0) {
      consoleLog('pdfDownloadx propertyOwner if>>>', propertyOwner);
      o_info = propertyOwner;
    } else {
      consoleLog('pdfDownloadx propertyOwner else>>>', typeof ownerInfo);
      if (Array.isArray(ownerInfo)) {
        o_info = ownerInfo[0];
        consoleLog('pdfDownloadx propertyOwner else if>>>', ownerInfo[0]);
      } else {
        o_info = ownerInfo;
        consoleLog('pdfDownloadx propertyOwner else else>>>', ownerInfo);
      }
    }
    consoleLog('pdfDownloadx o_info>>>', o_info);

    if (typeof pdf != 'undefined' && pdf != '') {
      consoleLog('pdfDownloadx abort download>>>');
    } else {
      consoleLog('pdfDownloadx start download>>>');
      if (typeof stData.structures != 'undefined') {
        setDownload(true);
        data.propertyOwner = o_info;
        data.contact = c_info;
        data.docDetail = stData;
        data.calcualatedData = calculateData(stData);
        data.invoice = invoice;
        data.client_default_data = JSON.parse(secureLocalStorage.getItem("client_data"));
        consoleLog('pdfDownloadx start generation>>>', data);

        try {
          //generate the PDF
          const response = API.graphql({
            query: generatePdf,
            variables: {
              docData: JSON.stringify(data)
            }
          });
          return response;
        }
        catch (err) {
          consoleLog('Error pdfDownloadx start generation>>>', err);
          return false;
        }

      } else {
        consoleLog('pdfDownloadx abort generation>>>');
        return false;
      }
    }
  }

  async function pdfDownload(sendMessage = 0) {
    let pdf;
    let data = {};

    if (invoice === 1) {
      pdf = stData.invoice_pdf;
    } else {
      pdf = stData.pdf;
    }
    consoleLog('pdfDownload>>>', pdf);

    if (sendMessage === 0) {
      setDownload(true);
    }

    if (isEmpty(pdf)) {
      data.propertyOwner = propertyOwner;
      data.contact = contact;
      data.docDetail = stData;
      data.calcualatedData = calculateData(stData);
      data.invoice = invoice;
      data.client_default_data = JSON.parse(secureLocalStorage.getItem("client_data"));
      consoleLog('pdfDownload start generation>>>', data);

      let response;
      try {
        //generate the PDF
        response = await API.graphql({
          query: generatePdf,
          variables: {
            docData: JSON.stringify(data)
          }
        });
      }
      catch (err) {
        consoleLog('Error pdfDownloadx start generation>>>', err);
        setDownload(false);
        return false;
      }
      const getUrl = JSON.parse(response?.data?.generatePdf)
      pdf = getUrl.message.Location;

      if (invoice === 1) {
        stData.invoice_pdf = pdf;
      } else {
        stData.pdf = pdf;
      }

      //update the document on elasticsearch
      let temp = JSON.stringify(stData);
      API.graphql({
        query: elasticSearchUpdateDocOrTemp,
        variables: {
          id: stData.doc_id,
          index: 'prodocs-documents',
          template: temp
        },
      });

      dispatch({ type: "TEMPLATE_STRUCTURE", value: stData });
    }

    if (sendMessage === 1) {
      setPdfUrl(pdf);
    } else {
      setDownload(false);
      window.open(pdf, "_blank");
    }
  }

  const emailPopup = async () => {
    setEmailPopupShow(true);
    pdfDownload(1);
  }

  async function sendDoc(emails, subject, message, url) {

    let validEmail = true;
    let toEmails = emails.split(',');
    let client = JSON.parse(secureLocalStorage.getItem('client_data'));

    if (isEmpty(emails) || isEmpty(subject) || isEmpty(message)) {
      setError('Fields with * are required');
    } else if (isEmpty(url)) {
      setError('Something went wrong please try again!');
    } else {
      for (const email of toEmails) {
        if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email.trim()))) {
          validEmail = false;
          break;
        }
      }

      if (validEmail) {
        setDocSend(true);

        const response = await API.graphql({
          query: sendEmail,
          variables: {
            subject: subject,
            to_email: emails,
            url_download: url,
            email_msg: message,
            client_email: client.email,
            client_id: client.client_id
          }
        });

        const result = JSON.parse(response.data.sendEmail);
        if (result.status === 422) {
          setMessage(result.message);
        }
        else if (result[0] && result[0].status === 'sent') {
          setMessage('Your document has been emailed to the requested recipients!');
          setMessageType('Success');
        } else {
          setMessage('We were unable to send the PDF document via email. Please try downloading and emailing the PDF manually.');
          setMessageType('Error');
        }
        setPdfUrl('');
        setShowMsg(true);
        setDocSend(false);
        setEmailPopupShow(false);
      } else {
        setError('Invalid Email address');
      }
    }
  }
  function switchInvoice(invoice){
    consoleLog('switchInvoice');
    const url = invoice === 1
    ? `/pdftable?invoice=1&preview=estpdf&doc_id=${url_params.doc_id}`
    : `/pdftable?preview=estpdf&doc_id=${url_params.doc_id}`;
    // Use navigate first to update the route
    navigate(url, { replace: true });
    // Force a page reload
    window.location.href = url;
    return 1;
  }
  function redirectPage(invoice) {
    consoleLog('redirectPage');
    if (invoice == 1) {
      navigate('/pdftable?invoice=1&preview=estpdf&doc_id=' + url_params.doc_id);
    } else {
      navigate('/pdftable?preview=estpdf&doc_id=' + url_params.doc_id);
    }
    pdfDownloadx(invoice).then(
      function (response) {
        consoleLog('pdfDownloadx response>>>', response);
        consoleLog('pdfDownloadx invoice>>>', invoice);
        if (typeof response != 'undefined') {
          const getUrl = JSON.parse(response?.data?.generatePdf);
          let pdf = getUrl?.message?.Location;
          let stDataCopy = stData;
          if (invoice == 1) {
            stDataCopy.invoice_pdf = pdf;
          } else {
            stDataCopy.pdf = pdf;
          }
          //update the document on elasticsearch
          let temp = JSON.stringify(stDataCopy);
          API.graphql({
            query: elasticSearchUpdateDocOrTemp,
            variables: {
              id: stData.doc_id,
              index: 'prodocs-documents',
              template: temp
            },
          });
          setStData(stDataCopy);
          dispatch({ type: "TEMPLATE_STRUCTURE", value: stDataCopy });
          setDownload(false);
        }
        else {
          setDownload(false);
        }
      }
    );
  }
  const countNewLines = (notes) => {
    return (notes.match(/\n/g) || []).length;
  };
  const estimateNoteLines = (notes, charsPerLine = 60) => {
      if (!notes) return 0;
  
      const wrappedLines = Math.ceil(notes.length / charsPerLine);
      const explicitNewLines = countNewLines(notes);
  
      return (wrappedLines + explicitNewLines) * 0.35;
  };
  const [stData, setStData] = React.useState(
    useSelector((state) => state.templateStructure)
  );
  const [loading, setLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const [subTotal, setSubTotal] = React.useState(0);
  const [taxes, setTaxes] = React.useState([]);
  const [markups, setMarkups] = React.useState([]);
  const [contact, setContact] = React.useState([]);
  const [propertyOwner, setPropertyOwner] = React.useState([]);
  const [download, setDownload] = React.useState(false);
  const [docSend, setDocSend] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [error, setError] = useState('');
  const [emailPopupShow, setEmailPopupShow] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');

  const [defLogo, setDefLogo] = useState('//roofscope.com/image/catalog/logo.png');
  const [defColor, setDefColor] = useState('#da8227');

  const options = stData.options ? JSON.parse(stData.options) : null;

  const color = options && options.color ? options.color : defColor;


  consoleLog('my browser is', browserName);
  const [paginatedItems, setPaginatedItems] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(0);

  const PAGE_HEIGHT = 1056; // Points
  const MARGIN_TOP = 37; // 1 inch
  const MARGIN_BOTTOM = 37; // 1 inch
  const LINE_HEIGHT = 31;
  const STRUCT_LINES = 4;
  const CATEGORY_LINES = 4;
  const HEADER_HEIGHT = 200;
  const HEADER_INVOICE_HEIGHT = 149;
  const FOOTER_HEIGHT = 72;
  const FINANCE_HEIGHT = 260;
  const LOGO_HEIGHT =123;
  const CONTENT_HEIGHT_FIRST_PAGE = PAGE_HEIGHT - MARGIN_TOP - MARGIN_BOTTOM - HEADER_HEIGHT;
  const CONTENT_HEIGHT_MIDDLE_PAGE = PAGE_HEIGHT - MARGIN_TOP - MARGIN_BOTTOM -FOOTER_HEIGHT;
  const LINES_PER_PAGE_MIDDLE = Math.floor(CONTENT_HEIGHT_MIDDLE_PAGE / LINE_HEIGHT);

  useEffect(() => {
    console.log("paginatedItems==>", paginatedItems);
    if (!stData?.structures) return;
    if(paginatedItems.length>0) return;
    const allLineItemsWithContext = stData.structures.flatMap((structure) =>
      structure?.categories?.flatMap((category) =>
        (category?.line_items || []).map((lineItem) => {
          let sublineItems = getSantizedSublineItem(lineItem?.sub_line_item); // Use `lineItem` here
          let lineItemsCount = 0;
    
          if (sublineItems.length > 0) {
            lineItemsCount = sublineItems.reduce((total, rec) => {
              let sublinesno = (rec.length > 65 ? Math.ceil(rec.length / 65) : 1);
              return total + sublinesno * 0.7;
            }, 0);
          }
          if(lineItem.name.length>60){ // in case lineitem name is greater then 60 characters
            lineItemsCount += 0.7;
          }
          console.log("lineItemsCount", sublineItems, lineItemsCount);
    
          return {
            ...lineItem,
            structureName: structure.name,
            structureId: structure.id,
            categoryName: category.name,
            categoryId: category.id,
            subLineItemCount: lineItemsCount,
          };
        })
      )
    );
  
    let paginatedData = [];
    let currentPage = [];
    let currentLineCount = 0;
    let noOfStructures = 0;
    let noOfCategories = 0;
    let isFirstPage = true;
    let lastStructureName = null;
    let lastCategoryName = null;

    const getFirstPageRows = () => {
      let firstPageContent = CONTENT_HEIGHT_FIRST_PAGE;
      if(options.logo?.replace("/", "")){
        firstPageContent = CONTENT_HEIGHT_FIRST_PAGE-LOGO_HEIGHT;
      }
      if(invoice){
        firstPageContent = firstPageContent-HEADER_INVOICE_HEIGHT;
      }
      console.log("firstPageContent===>",firstPageContent);
      return Math.floor(firstPageContent / LINE_HEIGHT)
    }
    const getLinesPerPage = (isFirstPage) =>
      isFirstPage
        ? getFirstPageRows() : LINES_PER_PAGE_MIDDLE;
  
    const addCurrentPage = () => {
      paginatedData.push({ currentPage, pageLineItems:currentLineCount });
      resetPageState();
    };
  
    const resetPageState = () => {
      isFirstPage = false;
      currentPage = [];
      currentLineCount = 0;
      noOfStructures = 0;
      noOfCategories = 0;
    };
  
    const fitsOnCurrentPage = (maxLinesForPage) => {
      console.log("fitsOnCurrentPage", currentLineCount , maxLinesForPage , currentLineCount <= maxLinesForPage);
      return currentLineCount <= maxLinesForPage;
    };
  
    allLineItemsWithContext.forEach((lineItem) => {
      const itemCount = 1 + lineItem.subLineItemCount;
      const maxLinesForPage = getLinesPerPage(isFirstPage);
      // Add structure and category headers if they change
      if (lineItem.structureName !== lastStructureName) {
        lastStructureName = lineItem.structureName;
        currentLineCount += STRUCT_LINES;
        if (!fitsOnCurrentPage(maxLinesForPage)) {
          addCurrentPage();
        }
        currentPage.push({
          type: "structure",
          structureId: lineItem.structureId,
          structureName: lineItem.structureName,
        });
      }
      if (lineItem.categoryName !== lastCategoryName) {
        lastCategoryName = lineItem.categoryName;
        currentLineCount += CATEGORY_LINES;
        if (!fitsOnCurrentPage(maxLinesForPage)) {
          addCurrentPage();
        }
        currentPage.push({
          type: "category",
          categoryId: lineItem.categoryId,
          categoryName: lineItem.categoryName,
        });
      }
      currentLineCount += itemCount;
      if (!fitsOnCurrentPage(maxLinesForPage)) {
        addCurrentPage();
        currentLineCount = itemCount;
      }
      currentPage.push(lineItem);
    });
  
    if (currentPage.length > 0) {
      addCurrentPage();
    }
  
    const handleLastPageSections = () => {
      const lastPage = paginatedData.at(-1);
      console.log("lastPage.pageLineItems",lastPage.pageLineItems);
      const remainingCapacity =
        getLinesPerPage(paginatedData.length==1?1:0) - lastPage.pageLineItems;
      if (!addSummarySection(remainingCapacity)) {
        addFinanceSection();
      }
    };
    const addSummarySection = (remainingCapacity) => { 
      let markupsarr = stData.markups;
      let marksectionlinesCount = 3;
      let dynamicMarklines
      if(typeof markupsarr === "string" && markupsarr !== "{}" && markupsarr){
          markupsarr = JSON.parse(markupsarr);
      }
      dynamicMarklines = 
        Object.entries(markupsarr).reduce((totalLines, [key, value]) => {
          const lineCount = Math.floor(value?.name?.length / 6);
          return totalLines + lineCount;
        }, 0) * 1.5;
      if(dynamicMarklines){
        marksectionlinesCount += dynamicMarklines;
      }
      console.log("markupsarr", markupsarr , stData.markups);
      console.log("marksectionlinesCount", marksectionlinesCount);
      let notes_lines = 0;
      if(stData?.notes?.length){
        notes_lines = estimateNoteLines(stData?.notes);
        if(notes_lines > marksectionlinesCount){
          marksectionlinesCount = notes_lines;
        }
      }
      console.log("notes_lines", notes_lines);
      const summarySectionHeight = marksectionlinesCount * LINE_HEIGHT;
      console.log("summarySectionHeight",summarySectionHeight ,"remainingCapacity", remainingCapacity , "calculation" ,remainingCapacity * LINE_HEIGHT);
      console.log("after adding summary", paginatedData);
      if (remainingCapacity * LINE_HEIGHT < summarySectionHeight) {
        currentLineCount = summarySectionHeight/LINE_HEIGHT;
        currentPage.push({ type: "summary" });
        addCurrentPage();
        return true;
      }else{
        let lastpage = paginatedData[paginatedData.length-1];
        lastpage.currentPage.push({ type: "summary" });
        lastpage.pageLineItems += Math.floor(summarySectionHeight/LINE_HEIGHT);
      }
      return false;
    };
  
    const addFinanceSection = () => {
      let financingOp =[];
      try{
        financingOp = JSON.parse(stData.financing_options);
      } catch (e){
        financingOp = financingOptions;
      }
      console.log("financingOp==>",financingOp);
      if (!invoice && financingOp.some(option => option.is_active === 'Y' && option.months > 0 && option.percentage > 0)) {
        const financeHeight = FINANCE_HEIGHT;
        let lastpage = paginatedData[paginatedData.length-1];
        const remainingCapacity =
          getLinesPerPage(paginatedData.length==1?1:0) - lastpage.pageLineItems;
        currentPage.push({ type: "finance" });
        console.log("addFinanceSection",financeHeight , remainingCapacity , lastpage );
        if (remainingCapacity * LINE_HEIGHT <= financeHeight) {
          addCurrentPage();
        }
      }
    };
  
    handleLastPageSections();
  
    paginatedData = paginatedData.filter((page) => page.currentPage.length > 0);
    console.log("Final paginated data:", paginatedData);
    setPaginatedItems(paginatedData);
    setTotalPages(paginatedData.length);
  }, [stData]);
   
  const componentRef = useRef(null);

  useEffect(() => {
    let dLogo = JSON.parse(secureLocalStorage.getItem('client_data')).logo;
    if (dLogo) {
      setDefLogo(dLogo);
      consoleLog('dLogo>>>', dLogo);
    }
    let dColor = JSON.parse(secureLocalStorage.getItem('client_data')).color;
    if (dColor) {
      setDefColor(dColor);
      consoleLog('dColor>>>', dColor);
    }
    consoleLog('useEffect>>>');
    const fetchData = async () => {
      await getDocument();
    }
    fetchData(); // Call the async function
  }, []);
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=756, initial-scale=1, shrink-to-fit=no" />
        <title>Prodocs</title>
      </Helmet>
      {emailPopupShow && <SendEmailModal
        show={emailPopupShow}
        setModalShow={setEmailPopupShow}
        error={error}
        docSend={docSend}
        pdfUrl={pdfUrl}
        onSendDoc={sendDoc}
      />}
      <ToastBox
        setShow={setShowMsg}
        show={showMsg}
        Text={message}
        Type={messageType}
      />
      {loading ? (
        <LoadingContainer>
          <img className="loader" src={Loader} />
        </LoadingContainer>
      ) : (
        <PDFS ref={componentRef}>
          <div className="mainDiv">
            <div className="downloadPDF-section no-print">
              <Tooltip
                overlay={
                  <div
                    className="d-flex flex-column justify-content-center pdf-tooltip"
                  >
                    <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Edit</p>
                  </div>
                }
                placement="bottom"
              >
                <a href={`/template?template_id=${stData.template_id}&&doc_type_id=${stData.doc_type_id}&&doc_id=${stData.doc_id}`} className="button-section">
                  <img src={edit} alt={'edit-icon'} />
                </a>
              </Tooltip>
              <ReactToPrint
                trigger={() =>
                  <Tooltip
                    overlay={
                      <div className="d-flex flex-column justify-content-center pdf-tooltip">
                        <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Print</p>
                      </div>
                    }
                    placement="bottom"
                  >
                    <div className={browserName == 'Chrome' ? "button-section" : ""} >
                      <img src={print} alt={'print-icon'} />
                    </div>
                  </Tooltip>
                }

                content={() => componentRef.current}
                bodyClass="print-body"
                pageStyle={printStyles}
              />
              <Tooltip
                overlay={
                  <div
                    className="d-flex flex-column justify-content-center pdf-tooltip"
                  >
                    <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Download</p>
                  </div>
                }
                placement="bottom"
              >
                {download ?
                  <div className="button-section">
                    <FaCircleNotch size={22} className="awesome_spinner" style={{ color: 'white' }} />
                  </div> :
                  <div className="button-section" onClick={() => pdfDownload(0)}>
                    <img src={arrowDown} alt={'arrowDown-icon'} />
                  </div>
                }
              </Tooltip>
              <Tooltip
                overlay={
                  <div
                    className="d-flex flex-column justify-content-center pdf-tooltip"
                  >
                    <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Email</p>
                  </div>
                }
                placement="bottom"
              >
                <div className="button-section" onClick={() => emailPopup()}>
                  <img src={email} alt={'email-icon'} />
                </div>
              </Tooltip>
              {
                invoice === 1 ?
                  <Tooltip
                    overlay={
                      <div
                        className="d-flex flex-column justify-content-center pdf-tooltip"
                      >
                        <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Estimate</p>
                      </div>
                    }
                    placement="bottom"
                  >
                    <div className="button-slip" onClick={() => switchInvoice(0)}>
                      <img src={calculator} alt={'calculator-icon'} />
                    </div>
                  </Tooltip>
                  :
                  <Tooltip
                    overlay={
                      <div
                        className="d-flex flex-column justify-content-center pdf-tooltip"
                      >
                        <p style={{ marginBottom: '0', fontWeight: 'bold' }}>Invoice</p>
                      </div>
                    }
                    placement="bottom"
                  >
                    <div className="button-calculator" onClick={() => switchInvoice(1)}>
                      <img src={file} alt={'file-icon'} />
                    </div>
                  </Tooltip>
              }
            </div>
            <MainDiv >
              <div>
              {paginatedItems.map((pageItems, pageIndex) => (
                  <Wrapper
                    key={`page-${pageIndex}`}
                    className={`pdf-container ${pageIndex > 0 ? "page-break print-margin-top" : ""}`}
                  >
                    {/* Header on the first page */}
                    {pageIndex === 0 && (
                      <EstimateHeader
                        options={options}
                        contact={contact}
                        defLogo={defLogo}
                        defContactPhoneNo={defContactPhoneNo}
                        stData={stData}
                        invoice={invoice}
                        defContactFirstName={defContactFirstName}
                        defContactLastName={defContactLastName}
                        defContactEmail={defContactEmail}
                        propertyOwner={propertyOwner}
                        total={total}
                      />
                    )}

                    {/* Render EstimateTable for the current page */}
                    <EstimateTable
                      item={pageItems.currentPage}
                      options={options}
                      stData={stData}
                      subTotal={subTotal}
                      taxes={taxes}
                      markups={markups}
                      total={total}
                      lastPage={pageIndex === totalPages - 1}
                    />
                    {/* Last page footer */}
                    {pageIndex === totalPages - 1 && (
                      <EstimateFooter
                        pageCount={pageIndex + 1}
                        totalPages={totalPages}
                        stData={stData}
                        subTotal={subTotal}
                        taxes={taxes}
                        markups={markups}
                        total={total}
                        invoice={invoice}
                        financingOptions={financingOptions}
                      />
                    )}
                    {/* Footer on every page */}
                    <PageFooter
                      stData={stData}
                      contact={contact}
                      defContactPhoneNo={defContactPhoneNo}
                      color={color}
                      pageCount={pageIndex + 1}
                      totalPages={totalPages}
                    />
                  </Wrapper>
                ))}

              </div>
            </MainDiv>
          </div>
        </PDFS>)}
    </>
  );
}

const LoadingContainer = styled.div`
  height:100vh;
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  background: transparent;
  .loader{
      -webkit-user-select: none;
      margin: auto;
  }
`
const PDFS = styled.div`
  width:100%;
  height:100%;
  background-color: #CCCCCC;
  padding-bottom: 30px;
  padding-top: 60px;
  color: #333;
  .loc-sec{
    background-color: #151f24 !important;
    padding: 13px 12px;
    color: #FFFFFF !important;
    margin-bottom: 0;
    border-radius: 13px 13px 0px 0px;
  }
  .header-contact{
    font-family:Rajdhani;
    font-size:16px;
    font-weight:700;
  }
  .border-radius-none {
    border-radius: 0;
  }
  .mainDiv {
    width: 816px;
    height: auto;
    margin: auto;

    @media (max-width: 767px) {
      width: 100%;
      overflow: hidden;
    }
  }
  @media print {
    .mainDiv {
      width: auto;
      overflow: hidden;
      margin: 0 auto;
      margin-top:-39px;
      page-break-inside: avoid;
    }
    .page-break {
      page-break-before: always;
    }
    @page {
      size: 8.5in 10in;
      margin: 0.3in;
    }
  }
  .notes-box{
    margin-bottom:20px;
  }
  .notes-box-text{
    margin-bottom:0px;
    padding-bottom:0px;
  }
  .button-section {
    border-radius: 3px;
    cursor: pointer;
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #414141;
    margin-right: 10px;
    &:hover {
      opacity: 0.5;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }
  .bg-white {
      background: #ffffff !important;
  }
  .bg-orange {
      background: #f79425 !important;
  }
  .col-orange {
      color: #f79425 !important;
  }
  .col-cyan {
      color: #5ce1bb !important;
  }
  @media print {
    .financial_report_container {
      padding: 37px !important;
      margin-inline: 0px !important;
    }
  }

  .button-calculator {
    cursor: pointer;
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:#7cc57c;
    img {
      width: 20px;
      height: 20px;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  .button-slip {
    cursor: pointer;
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:#f7941d;
    img {
      width: 20px;
      height: 20px;
    }
    &:hover {
      opacity: 0.5;
    }
  }
`;
const printStyles = `
    @media print {
      .print-margin-top{
        padding-top:37px;
      }
      .page-break {
        page-break-after: always;
      }
      .financial_report_container {
        padding: 0 !important; /* Remove padding for print */
      }
`;
const MainDiv = styled.div`
  width:100%;
  height:100%;
`;

const Wrapper = styled.div`
  margin-top: 1em;
  margin-bottom: 2em;
  padding: 37px;
  background-color: #ffffff;
  position: relative;
  height: 1056px;
  
  .container-pdf {
    padding: 0px !important;
  }

  @media print {
    padding: 0 !important;
    .page-break {
      page-break-before: always;
      break-before: page;
    }
  }

  .financial_report_container {
    background: #d6e1e5 !important;
    margin-inline: -37px;
    margin-block: 9px;
  }

  .cut-separator {
    position: relative;
    margin: 0 0 2em 0;
  }

  .cut-separator .icon {
   display: none;
    position: absolute;
    left: -12px;
    top: 15px;
    font-size: 1.6em;
  }

  .cut-separator .text {
    padding-bottom: 1em;
    color: #9d9fa2;
    border-bottom: 2px dashed #9d9fa2;
    width: 100%;
    text-align: center;
  }

  .p-left {
    padding-left: 0px;
  }

  .p-right {
    padding-right: 0px;
  }

  .quantity-row-pdf {
    display: -webkit-box;
  }
  
  .table-pdf {
    color: #212e34;
    width: 15%;
    padding: 5px 10px;
    border-right: 1px solid #d6e1e5 !important;
    border-bottom: 1px solid #d6e1e5 !important;
    word-wrap: break-word;
  }
  .bg-white{
    background-color: #fff !important;
  }
  .bg-grey{
    background-color: #D6E1E540 !important;
  }
  .table-pdf.col-name {
    width: 55%;
    padding:8px 10px;
  }
  
  .amount {
    border-left: 1px solid #d6e1e5 !important;
    border-right: 1px solid #d6e1e5 !important;
    border-top: 1px solid #d6e1e5 !important;
    font: normal normal 600 16px Rajdhani !important;
    letter-spacing: 0.42px !important;
    color: #212e34 !important;
  }
  .amount-first {
    border-left: 0px solid #d6e1e5 !important;
    border-right: 0px solid #d6e1e5 !important;
    border-top: 0px solid #d6e1e5 !important;
    border-top-right-radius: 10px !important;
    border-bottom: 1px solid #d6e1e5 !important;
    text-align: end !important;
    font: normal normal 600 16px Rajdhani !important;
    letter-spacing: 0.42px;
    color: #212e34 !important;
  }
  .font-total-small {
    font-size:15px !important;
  }  
  .font-total, .bg-pdf, .amount {
    border: none;
    text-align: end;
  }
  .font-total-background {
    background: #212e34 0% 0% no-repeat padding-box !important;
    border-bottom-left-radius: 10px;
    font: normal normal bold 18px Rajdhani !important;
    letter-spacing: 0.62px !important;
    color: #ffffff !important;
    text-transform: uppercase;
    opacity: 1 !important;
  }
  .total-background {
    background: #d6e1e5 0% 0% no-repeat padding-box;
    opacity: 1;
    font: normal normal bold 16px Rajdhani;
    letter-spacing: 0.42px;
    color: #212e34 !important;
    text-transform: uppercase;
    opacity: 1;
    border-bottom: 1px solid #fff;
    width: 10px;
  }
  .total-background-first {
    background: #d6e1e5 0% 0% no-repeat padding-box !important;
    border-radius: 10px 0px 0px 0px !important;
    opacity: 1;
    font: normal normal bold 16px Rajdhani !important;
    letter-spacing: 0.42px;
    color: #212e34 !important;
    text-transform: uppercase !important;
    opacity: 1 !important;
    border-bottom: 1px solid #fff !important;
    width: 10px ;
  }
  .total-background-last {
    background: #d6e1e5 0% 0% no-repeat padding-box !important;
    opacity: 1;
    font: normal normal bold 16px Rajdhani !important;
    letter-spacing: 0.42px;
    color: #212e34 !important;
    text-transform: uppercase !important;
    opacity: 1;
    width: 10px;
  }
  .bg-pdf {
    background: #f7941d !important;
    font-size: 14px !important;
    border-radius: 0px 0px 10px 0px !important;
    font: normal normal 800 18px Rajdhani !important;
    letter-spacing: 0.62px !important;
    color: #212e34 !important;
  }
  .custom-bg-pdf {
    font-size: 12px;
  }
  .w-55-p {
    width:57.5%;
  }

  .b-left {
    border-left: 1px solid #d6e1e5;
  }

  .b-top {
    border-top: 1px solid #d6e1e5;
  }
  .text-center-pdf {
    text-align: end !important;
    padding-top:8px;
  }
  .width-80-l {
    width: 80%;
    float: left;
  }

  .width-20-r {
    width: 20%;
    float: right;
    text-align: right;
  }
  
  @media print {
    .page-break {
      page-break-after: always !important;
    }
  }
  .estimate-number {
    width: 100%;
    float: right;
    padding: 5px;
    background-color: inherit;
  }
  .notes {
    margin-top: 10px;
    list-style-type: none;
    line-height: 22px;
    margin-bottom: 0px;
  }
  .m-top {
    margin-top: 20px;
  } 
  .m-bottom {
    margin-bottom: 20px;
  }
  .m-bottom-30 {
    margin-bottom: 30px;
  }
  .structure-wrapper .category-header {
    margin-top: 30px;
  }
  .structure-wrapper .structure-header + .category-header {
    margin-top: 0;
  }
  .custom-bg-dark-pdf-middle{
    background-color: #212e34 !important;
  }
  .custom-bg-dark-pdf-last{
    background-color: #212e34 !important;
  }
  .custom-bg-dark-pdf {
    background-color: #212e34 !important;
  }
  .custom-bg-grey-pdf{
    background-color: #D6E1E5 !important;
  }
  .custom-bg-grey-pdf-middle{
    background-color: #D6E1E5 !important;
  }
  .custom-bg-grey-pdf-last{
    background-color: #D6E1E5 !important;
  }
  .custom-total-table{
    border: 1px solid #d6e1e5 !important;
    border-radius: 10px !important;
    overflow: hidden !important;
    display: flex !important;
  }
  .custom-th-total-table{
    width: 10000px;
  }
}
  
`;