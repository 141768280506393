import React from "react";
import {
  calculateAdjQuantity,
  calculateNetCost,
  getSantizedSublineItem,
} from "../CommonComponents/helper";
import EstimateSummary from "./EstimateSummary";
function EstimateTable({ item, options, stData , ...props }) {
  let lastStructureId = null;
  let lastCategoryId = null;

  return (
    <div className="w-100 structure-wrapper" style={{ marginBottom: "20px" }}>
      <>
      {item.map((entry, index) => {
        if (entry.type === "structure") {
          // Render a structure header if it's a new structure
          lastStructureId = entry.structureId; // Update last seen structure
          lastCategoryId = null; // Reset category when structure changes
          return (
            <div className="w-100 structure-header" key={`structure-${entry.structureId}`}>
              <div
                className={`custom-pdf-bg-pdf w-100 header-title-main ${
                  stData?.location && index === 0 ? "border-radius-none" : ""
                }`}
              >
                <div
                  scope="col"
                  className="text-start-pdf w-100 custom-light-color-pdf"
                >
                  <div className="width-80-l">{entry.structureName}</div>
                  {options &&
                    parseInt(options.show_structure_price) === 1 && (
                      <div className="width-20-r">
                        ${entry.structurePrice?.toFixed(2) || "0.00"}
                      </div>
                    )}
                  <div style={{ clear: "both" }}></div>
                </div>
              </div>
            </div>
          );
        }

        if (entry.type === "category") {
          // Render a category header if it's a new category
          lastCategoryId = entry.categoryId; // Update last seen category
          return (
            <>
              <div className="w-100 category-header" key={`category-${entry.categoryId}`}>
                {options &&
                  parseInt(options?.show_categories) === 1 && (
                    <div className="custom-pdf-bg2-pdf w-100 header-title">
                      <div
                        scope="col"
                        className="text-start-pdf w-100 header-title"
                      >
                        <div
                          className="width-80-l"
                          style={{ color: "white !important" }}
                        >
                          {entry.categoryName}
                        </div>
                        {options &&
                          parseInt(options?.show_category_price) === 1 && (
                            <div className="width-20-r">
                              ${entry.categoryPrice?.toFixed(2) || "0.00"}
                            </div>
                          )}
                        <div style={{ clear: "both" }}></div>
                      </div>
                    </div>
                  )}
              </div>
              <div className="w-100">
              <div className="quantity-row-pdf">
                <div className="table-pdf col-name b-top b-left table-header">ITEM NAME</div>
                <div className="table-pdf b-top text-center-pdf table-header">QUANTITY</div>
                <div className="table-pdf b-top text-center-pdf table-header">PRICE</div>
                <div className="table-pdf b-top text-center-pdf table-header">AMOUNT</div>
              </div>
            </div>
           </>
          );
        }

        if (!entry.type) {
          // Render a line item
          const subLineItems =
            typeof entry.sub_line_item === "string"
              ? getSantizedSublineItem(entry.sub_line_item)
              : entry.sub_line_item || []; 
          if (calculateAdjQuantity(entry) > 0) {
            return (
              <div
                className={`w-100 ${
                  index % 2 === 0 ? "bg-white" : "bg-grey"
                }`}
                key={`lineItem-${entry.id}`}
                ref={(node) => {
                  if (node) {
                    node.style.setProperty(
                      "page-break-inside",
                      "avoid",
                      "important"
                    );
                    node.style.setProperty(
                      "page-break-after",
                      "auto",
                      "important"
                    );
                  }
                }}
              >
                
                <div className="quantity-row-pdf">
                  <div className="table-pdf col-name b-left">
                    {entry.name}
                    {subLineItems?.filter((note) => note)?.length > 0 && (
                      <ul className="notes">
                        {subLineItems
                          .filter((note) => note)
                          .map((note, noteIndex) => (
                            <li key={noteIndex}>{note}</li>
                          ))}
                      </ul>
                    )}
                  </div>
                  <div className="table-pdf text-center-pdf">
                    {entry.unit
                      ? calculateAdjQuantity(entry) + " " + entry.unit
                      : ""}
                  </div>
                  <div className="table-pdf text-center-pdf">
                    {entry.unit &&
                    (parseInt(entry.remove_prices) === 0 ||
                      typeof entry.remove_prices === "undefined")
                      ? `$${entry.cost}`
                      : ""}
                  </div>
                  <div className="table-pdf text-center-pdf">
                    {entry.unit &&
                    (parseInt(entry.remove_prices) === 0 ||
                      typeof entry.remove_prices === "undefined")
                      ? `$${calculateNetCost(entry)}`
                      : ""}
                  </div>
                </div>
              </div>
            );
          }
        }
        if(index ==item.length-1 && entry.type =='summary'){
          return <EstimateSummary 
                  stData={stData}
                  subTotal={props.subTotal}
                  taxes={props.taxes}
                  markups={props.markups}
                  total={props.total}
                />;
        }
      })}
       
                    </>
    </div>
  );
}
export default React.memo(EstimateTable);