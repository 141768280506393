import React, { useEffect, useState, useRef } from "react";
import {formatPhoneNumber} from '../CommonComponents/helper';
import { HiScissors } from "react-icons/hi";
import moment from "moment";

function EstimateHeader(props){
    const shouldRenderContainer = props.invoice === 1 
    ? (props.stData.invoice_label && props.stData.invoice_label.trim() !== '') 
    : (props.stData.label && props.stData.label.trim() !== '');

    return <>
            <div className="container-pdf">
                <div className="logo-pdf">
                <img src={props.options?.logo ? props.options.logo : props.defLogo} alt="" />
                </div>
            </div>
            <div class="header-contact">
                {props.contact ?
                <p style={{ textAlign: 'center' }}>
                    {props.contact?.company ? props.contact?.company : ''}
                    {(props.contact?.company && props.contact?.phone) && ' | '}
                    {props.contact?.phone ? '' + formatPhoneNumber(props.contact?.phone) : ''}
                </p>
                :
                <p style={{ textAlign: 'center' }}>
                    {props.defContactPhoneNo ? formatPhoneNumber(props.defContactPhoneNo) : ''}
                </p>
                }
            </div>
            {shouldRenderContainer && (
                <div className="container-pdf">
                    <div className="row w-100">
                        <div className="col-4 p-left">
                            <p className="estimate-pdf">&nbsp;</p>
                        </div>
                        <div className="col-8">
                            <p className="estimate-pdf-label">
                                {props.invoice === 1
                                    ? props.stData.invoice_label
                                    : props.stData.label
                                }
                            </p>
                        </div>
                    </div>
                </div>
            )}
            <div className="container-pdf">
                <div className="row w-100">
                <div className="col-8 p-left">
                    {props.contact ? (
                    <>
                        <table className="table custom-table-bordered m-bottom-30">
                        <thead className="custom-th-width">
                            <tr>
                            <th
                                scope="col"
                                className="text-end-pdf custom-bg-grey-pdf"
                            >
                                PREPARED BY
                            </th>
                            <th
                                scope="col"
                                className="custom-bg-pdf-first th-contact-width"
                            >
                                {props.contact.first_name}{" "}
                                {props.contact.last_name}
                            </th>
                            </tr>
                            <tr>
                            <th
                                scope="col"
                                className="text-end-pdf custom-bg-grey-pdf-middle"
                            >
                                EMAIL
                            </th>
                            <th
                                scope="col"
                                className="custom-bg-pdf-second"
                            >
                                {props.contact.email}
                            </th>
                            </tr>
                            <tr>
                            <th
                                scope="col"
                                className="text-end-pdf custom-bg-grey-pdf-last"
                            >
                                CLAIM #
                            </th>
                            <th
                                scope="col"
                                className="custom-bg-pdf-last"
                            >
                                {props.stData.claim_number}
                            </th>
                            </tr>
                        </thead>
                        </table>
                    </>
                    )
                    :
                    <>
                        <table className="table custom-table-bordered ">
                        <thead className="custom-th-width">
                            <tr>
                            <th
                                scope="col"
                                className="text-end-pdf custom-bg-grey-pdf"
                            >
                                PREPARED BY
                            </th>
                            <th
                                scope="col"
                                className="custom-bg-pdf-first th-contact-width"
                            >
                                {props.defContactFirstName}{" "}
                                {props.defContactLastName}
                            </th>
                            </tr>
                            <tr>
                            <th
                                scope="col"
                                className="text-end-pdf custom-bg-grey-pdf-middle"
                            >
                                EMAIL
                            </th>
                            <th
                                scope="col"
                                className="custom-bg-pdf-second"
                            >
                                {props.defContactEmail}
                            </th>
                            </tr>
                            <tr>
                            <th
                                scope="col"
                                className="text-end-pdf custom-bg-grey-pdf-last"
                            >
                                CLAIM #
                            </th>
                            <th
                                scope="col"
                                className="custom-bg-pdf-last"
                            >
                                {props.stData.claim_number}
                            </th>
                            </tr>
                        </thead>
                        </table>
                    </>
                    }
                </div>
                <div className="col-4">
                    <table className="table custom-table-bordered ">
                    <thead className="custom-th-width">
                        <tr>
                        <th scope="col" className="text-end-pdf custom-bg-dark-pdf">DATE</th>
                        <th scope="col" className="custom-bg-pdf-first w-100" style={{ minWidth: '184px' }}>{moment().format("MM/DD/YYYY")}</th>
                        </tr>
                        <tr>
                        <th scope="col" className="text-end-pdf custom-bg-dark-pdf-middle">ESTIMATE #</th>
                        <th scope="col" className="custom-bg-pdf-second">{props.stData.ref_id}</th>
                        </tr>
                        <tr>
                        <th scope="col" className="text-end-pdf custom-bg-dark-pdf-last" style={{ borderBottom: '0px' }}>STRUCTURES</th>
                        <th scope="col" className="custom-bg-pdf-last">{props.stData.structure_count}</th>
                        </tr>
                    </thead>
                    </table>
                </div>
                </div>
            </div>
            {props.invoice === 1 ? (<>
                <div className="container-pdf">
                <div className="row w-100">
                    <div className="col-5 p-left">
                    <table className="table custom-table-bordered custom-table-bordered-1">
                        <thead className="custom-th-width">
                        <tr>
                            <th scope="col" className="text-end-pdf custom-bg-dark-pdf">AMOUNT DUE</th>
                            <th scope="col" className="custom-bg-pdf custom-bg-pdf-1 w-55-p">${props.total}</th>
                        </tr>
                        <tr>
                            <th scope="col" className="text-end-pdf custom-bg-dark-pdf-last">ENCLOSED</th>
                            <th scope="col" className="custom-bg-pdf-last"></th>
                        </tr>
                        </thead>
                    </table>
                    </div>
                </div>
                </div>
                <div className="container-pdf cut-separator">
                <div className="icon">
                    <HiScissors style={{ color: "#737f85" }} />
                </div>
                <div className="text">Please detach top portion and return with your payment</div>
                </div>
            </>) : null}
            {(props.propertyOwner?.name || props.stData?.location)? (<>
            <div className="container-pdf">
                <div className="w-100">
                <p className="pdf-details-pdf loc-sec">
                {props.propertyOwner?.name}
                {(props.propertyOwner?.name && props.stData?.location) && ' - '}
                {props.stData?.location}
                </p>
                </div>
            </div>
            </>) : null}
            </>;
}
export default React.memo(EstimateHeader);